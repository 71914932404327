import { FC, memo, useCallback } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Locales } from '@/constants/common';

type ToggleLocalProps = {
  readonly currentLocale: string;
  readonly onChangeLocale: (newLang: Locales) => void;
};

export const ToggleLocale: FC<ToggleLocalProps> = memo(function ToggleLocaleComponent({
  currentLocale,
  onChangeLocale,
}: ToggleLocalProps) {
  const handleLocalChange = useCallback(
    (_event: unknown, newLocale: Locales): void => {
      if (!newLocale) {
        return;
      }
      onChangeLocale(newLocale);
    },
    [onChangeLocale],
  );

  return (
    <>
      <ToggleButtonGroup color="primary" value={currentLocale} exclusive onChange={handleLocalChange}>
        <ToggleButton sx={{ border: 'none' }} value={Locales.de}>
          {Locales.de.toUpperCase()}
        </ToggleButton>
        <ToggleButton sx={{ border: 'none' }} value={Locales.en}>
          {Locales.en.toUpperCase()}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
});
