import { useMutation, useQuery } from '@tanstack/react-query';

import { CompanyDataParams, DocumentVerificationPayload, verifyApi } from '@/api/verify';

import { MUTATIONS } from '@/queries/mutations';
import { QUERIES } from '@/queries/queries';

import { CompanyAddressData, CompanyAutoCompleteOption, LegalEntityRepresentativesRequest } from '@/types/interfaces';
import {
  CompanyDataResponse,
  ErrorResponse,
  LegalEntityAutosuggestionResponse,
  LegalEntityRepresentativeDataResponse,
  PosRedirectUrlResponse,
} from '@/types/responses';
import { QueryParamsWithProcessAndSubProcessId } from '@/types/types';

export const verifyQueries = {
  useAutosuggestions: (params: { query: string; limit: string }) => {
    const query = useQuery<
      (LegalEntityAutosuggestionResponse[number] & CompanyAutoCompleteOption)[],
      ErrorResponse,
      (LegalEntityAutosuggestionResponse[number] & CompanyAutoCompleteOption)[],
      [string, { query: string; limit: string }]
    >({
      queryKey: [QUERIES.LEGAL_PERSON_AUTOSUGGESTIONS_LIST, params],
      queryFn: () => verifyApi.autosuggestions(params),
      enabled: !!params.query,
    });
    return query;
  },
  useRepresentativeData: (params: { processId: string; subProcessId: string }) => {
    const query = useQuery<
      LegalEntityRepresentativeDataResponse,
      ErrorResponse,
      LegalEntityRepresentativeDataResponse,
      [string, { processId: string; subProcessId: string }]
    >({
      queryKey: [QUERIES.LEGAL_PERSON_REPRESENTATIVE_DATA, params],
      queryFn: () => verifyApi.representativeData(params),
      staleTime: 0,
    });
    return query;
  },
  useSelectRepresentative: () => {
    const mutation = useMutation<
      { success: boolean },
      ErrorResponse,
      { params: { processId: string; subProcessId: string }; data: LegalEntityRepresentativesRequest }
    >({
      mutationKey: [MUTATIONS.LEGAL_PERSON_REPRESENTATIVE_SELECT],
      mutationFn: ({ params, data }) =>
        verifyApi.selectRepresentative({ processId: params.processId, subProcessId: params.subProcessId }, data),
    });
    return mutation;
  },
  useDocumentVerification: () => {
    const mutation = useMutation<
      unknown,
      ErrorResponse,
      { params: { processId: string; subProcessId: string }; data: DocumentVerificationPayload }
    >({
      mutationKey: [MUTATIONS.LEGAL_PERSON_DOCUMENT_VERIFICATION],
      mutationFn: ({ params, data }) =>
        verifyApi.documentVerification({ processId: params.processId, subProcessId: params.subProcessId }, data),
    });
    return mutation;
  },
  useUpdateLegalEntityAddress: () => {
    const mutation = useMutation<
      unknown,
      ErrorResponse,
      {
        params: { processId: string; subProcessId: string };
        data: Omit<CompanyAddressData, 'country'> & { northDataId: string };
      }
    >({
      mutationKey: [MUTATIONS.LEGAL_PERSON_ADDRESS_UPDATE],
      mutationFn: ({ params, data }) =>
        verifyApi.updateLegalEntityAddress({ processId: params.processId, subProcessId: params.subProcessId }, data),
    });
    return mutation;
  },
  useUndoProcessStep: () => {
    const mutation = useMutation<
      unknown,
      ErrorResponse,
      {
        params: { processId: string; subProcessId: string };
      }
    >({
      mutationKey: [MUTATIONS.UNDO_PROCESS_STEP],
      mutationFn: ({ params }) =>
        verifyApi.undoProcessStep({ processId: params.processId, subProcessId: params.subProcessId }),
    });
    return mutation;
  },
  useCompanyData: (params: CompanyDataParams) => {
    const query = useQuery<CompanyDataResponse, ErrorResponse>({
      queryKey: [QUERIES.COMPANY_DATA, params.companyId],
      queryFn: () => verifyApi.getCompanyData(params),
      staleTime: 20 * 60 * 1000,
      cacheTime: 25 * 60 * 1000,
    });
    return query;
  },
  useCancelProcess: () => {
    const mutation = useMutation<unknown, ErrorResponse, QueryParamsWithProcessAndSubProcessId>({
      mutationKey: [MUTATIONS.VERIFY_CANCEL_PROCESS],
      mutationFn: params => verifyApi.cancelProcess(params),
    });
    return mutation;
  },
  useRetrieveRedirectUrl: () => {
    const query = useMutation<PosRedirectUrlResponse, ErrorResponse, QueryParamsWithProcessAndSubProcessId>({
      mutationKey: [MUTATIONS.VERIFY_REDIRECT_URL],
      mutationFn: params => verifyApi.retrieveRedirectUrl(params),
    });
    return query;
  },
};
